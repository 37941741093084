
import CameraListItem from "@/components/CameraListItem"
import TheSidebarLoadingPlaceholder from "@/components/TheSidebarLoadingPlaceholder"
import TheSidebarItem from "@/components/TheSidebarItem"
import { mapStores } from "pinia"
import { useLayoutStore } from "@/stores/layout"
import { useProjectStore } from "@/stores/project"
import { useCameraStore } from "@/stores/camera"
import { useAccountStore } from "@/stores/account"
import { CameraStatus } from "@evercam/api/types"

export default {
  name: "TheSidebarCamerasList",
  components: {
    TheSidebarLoadingPlaceholder,
    TheSidebarItem,
    CameraListItem,
  },
  props: {
    listStyle: {
      type: String,
      default: () => "",
    },
  },
  computed: {
    ...mapStores(
      useLayoutStore,
      useProjectStore,
      useCameraStore,
      useAccountStore
    ),
    decommissionedProjectCameras() {
      return this.projectStore.selectedProjectCameras.filter(
        (camera) => camera.status === CameraStatus.Decommissioned
      )
    },
    activeProjectCameras() {
      return this.projectStore.selectedProjectCameras.filter(
        (camera) => camera.status !== CameraStatus.Decommissioned
      )
    },
  },
}
