
import { CameraStatus } from "@evercam/shared/types/camera"
import { ProjectFeatureFlag } from "@evercam/shared/types/project"
import CameraCard from "@evercam/shared/components/CameraCard.vue"
import Vue from "vue"
import NdaIcon from "@/components/NdaIcon"
import CameraStatusIcon from "@evercam/shared/components/CameraStatusIcon"
import { mapStores } from "pinia"
import { useProjectStore } from "@/stores/project"
import { useAccountStore } from "@/stores/account"

export default Vue.extend({
  name: "CameraListItem",
  components: {
    NdaIcon,
    CameraStatusIcon,
    CameraCard,
  },
  props: {
    camera: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapStores(useProjectStore, useAccountStore),
    isUnderNda() {
      return this.projectStore.selectedProject?.featureFlags?.includes(
        ProjectFeatureFlag.NdaMc
      )
    },
    item() {
      let param = ""
      let camera = this.camera
      if (
        [CameraStatus.Decommissioned, CameraStatus.OnHold].includes(
          camera?.status
        )
      ) {
        param = "/recordings"
      }

      return {
        offlineReason:
          camera?.status === CameraStatus.OFFLINE ? camera?.offlineReason : "",
        storageDuration: camera?.cloudRecordings
          ? camera?.cloudRecordings?.storageDuration
          : "",
        title: camera?.name,
        id: camera?.id,
        status: camera?.status,
        recordingStatus: camera?.cloudRecordings
          ? camera?.cloudRecordings?.status
          : "",
        to: `/v2/projects/${camera?.project?.id}/${camera?.id}${param}`,
        project: camera?.project?.id,
        bimView: camera?.bimView,
      }
    },
  },
})
